.c-form {
    display: flex;
    flex-direction: column;
    gap: rem(40);
    @include fluid(gap, 20px, 30px, $large, $container);
    text-align: left;

    &__group {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__group:focus-within &__label {
        color: $c-secondary;
    }

    &__row {
        display: flex;
        flex-direction: column;
        gap: rem(25);
    }

    &__label {
        font-family: $f-futura-pt;
        text-transform: uppercase;
        font-weight: 500;
        font-size: rem(12);
        padding-bottom: rem(10);
    }

    &__control {
        padding-bottom: rem(10);
        border-bottom: rem(1) solid $c-grey-3;
        font-family: $f-futura-pt;
        font-size: rem(14);
        font-weight: 400;
        border-radius: 0;
        line-height: 1.2;

        &:focus {
            border-bottom: rem(1) solid $c-secondary;
        }
    }

    @include screen(l) {
        &__row {
            flex-direction: row;
        }

        &__label {
            font-size: rem(13);
        }

        &__control {
            font-size: rem(14);
        }
    }
}
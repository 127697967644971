.c-leaf {
    position: relative;
     display: none;

    &__image {
        position: fixed;
        z-index: -1;

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
            bottom: 0;
        }
    }

    @include screen(l) {
       display: block;
    }
}
.o-container {
    width: 100%;
    max-width: $screen;
    margin-left: auto;
    margin-right: auto;
    @include fluid(padding-left, 10px, 150px, $large, $container);
    @include fluid(padding-right, 10px, 150px, $large, $container);

    &--small {
        @include fluid(padding-left, 20px, 250px, $large, $container);
        @include fluid(padding-right, 20px, 250px, $large, $container);
    }

    &--read {
        @include fluid(padding-left, 20px, 550px, $medium, $screen);
        @include fluid(padding-right, 20px, 550px, $medium, $screen);
    }

    &__small {
        @include fluid(padding-left, 10px, 100px, $large, $container);
        @include fluid(padding-right, 10px, 100px, $large, $container);
    }
}
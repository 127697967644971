$c-black: #000000;
$c-white: #ffffff;
$c-background: #EFEFEF;
$c-grey-1: #E8E8E8;
$c-grey-2: #E2E2E2;
$c-grey-3: #DBDADA;
$c-grey-4: #8B8B8B;
$c-grey-5: #666666;
$c-grey-6: #363636;
$c-grey-7: #666666;
$c-secondary: #0089AA;
$c-primary: #0B406D;
$c-secondary-light: #E2EAEB;
$c-primary-light: #E3E6E8;
$c-error: #cd2222;
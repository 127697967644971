.c-rich-text {
    h2 {
        @include fluid(margin-top, 25px, 35px, $large, $container);
    }

    h3 {
        @include fluid(margin-top, 20px, 30px, $large, $container);
    }

    h4 {
        @include fluid(margin-top, 15px, 25px, $large, $container);
        @include fluid(margin-bottom, 6px, 8px, $large, $container);
    }
}
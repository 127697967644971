.c-contact {
    display: flex;
    flex-direction: column-reverse;
    @include fluid(margin-bottom, 80px, 200px, $large, $container);

    &__content {
        @include fluid(margin-top, 100px, 160px, $large, $container);
        @include fluid(padding-left, 20px, 100px, $large, $container);
        @include fluid(padding-right, 20px, 150px, $large, $container);
        width: 100%;
        margin-bottom: rem(40);
    }

    &__maps-wrapper {
        position: relative;
        padding-top: 100%;
    }

    &__maps {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__business-info {
        @include fluid(margin-top, 40px, 80px, $large, $container);
    }

    &__message {
        display: flex;
        font-family: $f-futura-pt;
        font-weight: 500;
        margin-top: rem(15);
        @include fluid(font-size, 14px, 16px, $large, $container);
        align-items: center;
        font-family: $f-futura-pt;
        border-radius: rem(100);
        width: 100%;
        padding: rem(20) rem(25);
    }

    &__message-icon {
        margin-right: rem(12);
    }

    &__business {
        font-family: $f-futura-pt;
        font-weight: 500;
        margin-bottom: rem(10);
        display: block;
        font-size: rem(16);
    }

    &__info {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @include fluid(gap, 15px, 40px, $large, $container);

        p {
            color: $c-grey-4;
            font-size: rem(14);
        }

        strong {
            color: $c-grey-5;
        }
    }

    &__social {
        @include fluid(margin-top, 15px, 20px, $large, $container);
    }

    &__title {
        @include fluid(margin-bottom, 25px, 40px, $large, $container);
    }

    @include screen(l) {
        flex-direction: row;

        &__maps-wrapper {
            min-width: 50vw;
            height: 100vh;
            padding: 0;
        }

        &__info {
            flex-direction: row;
        }
    }
}
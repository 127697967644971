.c-blog {
    @include fluid(padding-left, 0px, 100px, $container, $screen);
    @include fluid(padding-right, 0px, 100px, $container, $screen);

    h2,
    &__video,
    &__image-wrapper {
        @include fluid(margin-top, 25px, 35px, $large, $container);
    }

    h3 {
        @include fluid(margin-top, 20px, 30px, $large, $container);
    }

    h4 {
        @include fluid(margin-top, 15px, 25px, $large, $container);
        @include fluid(margin-bottom, 6px, 8px, $large, $container);
    }

    &__wrapper {
        background: $c-background;
        @include fluid(padding-top, 30px, 100px, $small, $container);
        display: flex;
        flex-direction: column;
    }

    &__container {
        @include fluid(padding-right, 30px, 100px, $small, $container);
        @include fluid(padding-left, 30px, 100px, $small, $container);
    }

    &__content {
        max-width: rem(1000);
    }

    &__labels {
        display: flex;
        gap: rem(6);
        margin-bottom: rem(4);
    }

    &__label {
        font-family: $f-futura-pt;
        font-size: rem(10);
        text-transform: uppercase;
        font-weight: 600;
        color: $c-secondary;
        background: $c-secondary-light;
        padding: rem(6) rem(10);
        border-radius: rem(50);
    }

    &__aside {
        display: flex;
        flex-direction: column;
        margin-top: rem(50);
    }

    &__writter {
        display: flex;
        gap: rem(14);
        align-items: center;
    }

    &__writter-image {
        width: rem(70);
        height: rem(70);
        border-radius: 100%;
    }

    &__writter-info {
        display: flex;
        flex-direction: column;
        font-family: $f-futura-pt;
    }

    &__writter-name {
        font-size: rem(16);
        font-weight: 500;
        color: $c-black;
        margin-bottom: rem(2);

    }

    &__writter-description {
        font-size: rem(14);
        font-weight: 400;
        color: $c-grey-4;
    }

    &__socials {
        display: flex;
        align-items: center;
        gap: rem(12);
    }

    &__image-wrapper {
        width: 100%;
        position: relative;
        padding-top: calc((3/5) * 100%);
        @include fluid(margin-bottom, 30px, 40px, $large, $container);
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__video {
        width: 100%;
        @include fluid(margin-bottom, 30px, 40px, $large, $container);
    }

    &__highlight,
    &__share {
        display: none;
    }

    &__share-title {
        margin-bottom: 0;
    }

    &__button {
        margin-bottom: rem(20);
    }

    &__footer {
        @include fluid(margin-top, 40px, 60px, $large, $container);
        @include fluid(padding-top, 30px, 40px, $large, $container);
        @include fluid(margin-right, 30px, 100px, $small, $container);
        @include fluid(margin-left, 30px, 100px, $small, $container);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: rem(20);
        border-top: rem(1) solid $c-grey-3;
    }

    &__social-copy {
        position: relative;
        cursor: pointer;
    }

    &__social-copy:hover & {
        &__social-copy-text {
            display: block;
        }
    }

    &__social-copy-text {
        display: none;
        white-space: nowrap;
        position: absolute;
        bottom: 150%;
        left: 50%;
        transform: translateX(-50%);
        background: $c-secondary;
        padding: rem(10) rem(15);
        border-radius: rem(50);
        color: $c-white;
        font-family: $f-futura-pt;
        font-weight: 500;
        font-size: rem(12);

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: rem(-5);
            border-width: rem(5);
            border-style: solid;
            border-color: $c-secondary transparent transparent transparent;
        }
    }

    @include screen(l) {
        &__wrapper {
            flex-direction: row;
        }

        &__aside {
            max-width: rem(320);
            margin-top: 0;
            margin-left: auto;
            padding-left: 0;
        }

        &__button {
            margin-bottom: rem(40);
        }

        &__writter {
            margin-bottom: rem(30);
        }

        &__highlight {
            display: block;
        }

        &__highlight-item {
            display: block;

            &:not(:last-child) {
                padding-bottom: rem(15);
                border-bottom: rem(1) solid $c-grey-3;
            }

            &:not(:first-child) {
                padding-top: rem(15);
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &__highlight-text {
            font-size: rem(14);
        }

        &__share {
            display: block;
            margin-bottom: rem(40);
        }
    }
}
.c-footer {
    padding-bottom: rem(20);

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        align-items: center;
        margin-bottom: rem(30);
        gap: rem(20);
    }

    &__button {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    &__logo {
        grid-column: 1/3;
        grid-row: 1/2;
        justify-self: center;
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 2/3;
        grid-row: 1/2;
        justify-self: end;
        border-radius: 100%;
        background: $c-primary-light;
        width: rem(30);
        height: rem(30);
    }

    &__social-icon {
        width: rem(6);
    }

    &__description {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__privacy {
        color: $c-grey-4;
        font-family: $f-futura-pt;
        font-size: rem(12);
        font-weight: 400;
        text-decoration: underline;
    }

    &__sponsor{
        display: flex;
        align-items: center;
        gap: rem(18);
    }

    @include screen(l) {
        padding-bottom: rem(30);

        &__content {
            grid-template-rows: 1fr;
        }

        &__button {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        &__social {
            width: rem(40);
            height: rem(40);
        }

        &__social-icon {
            width: rem(8);
        }
    }
}
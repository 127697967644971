.c-image {
    position: relative;
    padding-top: 100%;
    width: 100%;

    &__src {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include screen(s) {
        padding-top: calc((3/4) * 100%);
    }

    @include screen(l) {
        padding-top: calc((9/16) * 100%);
    }
}
.c-background {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    @include fluid(height, 200px, 550px, $small, $container);

    &::after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.c-result {
    &__heading {
        @include fluid(margin-bottom, 20px, 40px, $large, $container);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__submissions {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-primary;
        background: $c-secondary-light;
        font-family: $f-futura-pt;
        white-space: nowrap;
        font-weight: 500;
        gap: rem(8);
        border-radius: rem(50);
        padding: rem(14) rem(20);
        @include fluid(font-size, 14px, 16px, $large, $container);

        &--count {
            color: $c-secondary;
        }
    }

    &__item {
        &:not(:last-child) {
            @include fluid(margin-bottom, 30px, 50px, $large, $container);
        }
    }

    &__title {
        color: $c-secondary;
        margin-bottom: rem(15);
    }

    &__answer {
        margin-bottom: rem(8);
        font-family: $f-futura-pt;
        font-size: rem(14);
        font-weight: 500;
        display: flex;
        align-items: center;
        @include fluid(font-size, 12px, 14px, $large, $container);
    }

    &__question {
        display: block;
        width: 100%;
        color: $c-primary;
        @include fluid(max-width, 90px, 120px, $large, $container);
    }

    &__bar {
        position: relative;
        display: block;
        height: rem(6);
        background: $c-primary-light;
        border-radius: rem(50);
        width: 100%;
    }

    &__progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: $c-secondary;
        border-radius: rem(50);
    }

    &__percentage {
        display: block;
        width: 100%;
        color: $c-secondary;
        text-align: right;
        @include fluid(max-width, 40px, 50px, $large, $container);
    }

    @include screen(l){
         &__submissions{
             padding: rem(15) rem(25);
         }
    }
}
.c-text-image {
    display: flex;
    flex-direction: column;
    gap: rem(18);
    position: relative;

    &__content {
        flex-basis: 50%;
        @include fluid(max-width, 620px, 800px, $large, $container);
        @include fluid(padding-left, 10px, 100px, $large, $container);
        @include fluid(padding-right, 10px, 100px, $large, $container);
    }

    &__image-container {
        flex-basis: 50%;
        order: -1;
    }

    &__image-wrapper {
        position: relative;
        padding-top: calc((3/4) * 100%);
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__illustration {
        display: block;
        width: 80%;
        max-width: rem(400);
        margin: 0 auto;
        @include fluid(padding-left, 10px, 100px, $large, $container);
        @include fluid(padding-right, 10px, 100px, $large, $container);
    }

    @include screen(s) {
        &__image-wrapper {
            padding-top: calc((1/2) * 100%);
        }
    }

    @include screen(l) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: rem(24);

        &--reverse {
            flex-direction: row-reverse;
        }

        &__image-container {
            order: 0;
        }

        &__image-wrapper {
            padding-top: calc((4/5) * 100%);
        }

        &__illustration {
            width: auto;
            max-width: 100%;

            &--large {
                padding: 0;
                width: 90%;
            }
        }
    }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $f-playfair;
  line-height: 1.2;
  color: $c-primary;
  font-weight: 400;
}

h1 {
  @include fluid(font-size, 26px, 38px, $large, $container);
  @include fluid(margin-bottom, 14px, 20px, $large, $container);
}

h2 {
  @include fluid(font-size, 22px, 32px, $large, $container);
  @include fluid(margin-bottom, 12px, 16px, $large, $container);
}

h3 {
  @include fluid(font-size, 18px, 24px, $large, $container);
  @include fluid(margin-bottom, 8px, 12px, $large, $container);
}

h4 {
  @include fluid(font-size, 16px, 18px, $large, $container);
}

h5 {
  @include fluid(font-size, 14px, 16px, $large, $container);
  @include fluid(margin-bottom, 12px, 14px, $large, $container);
  font-weight: 500;
  color: $c-black;
  text-transform: uppercase;
}

h6 {
  @include fluid(font-size, 14px, 16px, $large, $container);
  @include fluid(margin-bottom, 4px, 6px, $large, $container);
}

p,
li {
  font-family: $f-futura-pt;
  font-weight: 400;
  line-height: 1.35;
  color: $c-grey-6;
  @include fluid(font-size, 15px, 18px, $large, $container);
  letter-spacing: rem(-.2);
}

p {
  a:hover {
    text-decoration: underline;
  }
}

a {
  font-weight: 500;
  color: $c-primary;
  text-decoration: none;
}

strong {
  font-weight: 500;
}
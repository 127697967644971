.c-nav {
    position: relative;
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    padding-top: rem(15);
    padding-bottom: rem(15);

    &--active {
        background: $c-background;
        border-bottom: rem(1) solid $c-grey-2;
    }

    &--no-transition & {

        &__button,
        &__link,
        &__menu-dot::before,
        &__menu-dot::after,
        &__menu-close::before {
            transition: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $screen;
        margin-left: auto;
        margin-right: auto;
        @include fluid(padding-left, 20px, 150px, $large, $container);
        @include fluid(padding-right, 20px, 150px, $large, $container);
    }

    &__link {
        font-family: $f-futura-pt;
    }

    &__progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    &__progress-indicator {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(2);
        background: $c-secondary;
    }

    &__home {
        z-index: 10;
    }

    &__logo {
        width: rem(160);
    }

    &__menu {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transform: translateY(-50%);
        padding-right: rem(20);
        right: 0;
        top: 50%;
        height: 100%;
        width: 20%;
    }

    &__menu--active & {

        &__menu-dot::before,
        &__menu-dot::after,
        &__menu-close::before {
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }

        &__menu-close::before {
            width: rem(20);
        }
    }

    &__menu-dot {
        position: relative;
        cursor: pointer;
        width: rem(30);
        height: rem(25);
        display: block;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: rem(4);
            height: rem(4);
            border-radius: rem(100);
            background-color: $c-primary;
            transition: all .1s ease-in-out;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            left: calc(85% - .4rem)
        }

        &::before {
            left: 15%;
        }
    }

    &__menu-close::before {
        content: '';
        position: absolute;
        width: rem(4);
        height: rem(4);
        border-radius: rem(100);
        background-color: $c-primary;
        transition: all .2s ease-in-out;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include screen(l-mobile) {

        &--white & {

            &__menu-close::before,
            &__menu-dot::before,
            &__menu-dot::after {
                background-color: $c-white;
            }
        }

        &--disable-scroll {
            overflow: hidden;
            -ms-scroll-chaining: none;
            overscroll-behavior: none;
            height: calc(var(--window-inner-height) - 1px);
            box-sizing: border-box;
        }

        &__content {
            display: none;
            position: fixed;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(var(--window-inner-height) - 1px);
            background: $c-primary-light;
            padding: rem(30);
            gap: rem(20);
            overscroll-behavior: contain;
            -ms-scroll-chaining: none;

            &--active {
                display: flex;
            }
        }

        &__link {
            font-size: rem(20);
        }

        &__button {
            font-size: rem(18);
            padding: rem(15) rem(25);
        }
    }

    @include screen(l) {
        @include fluid(padding-top, 25px, 35px, $small, $container);
        @include fluid(padding-bottom, 25px, 35px, $small, $container);


        &--transition {
            transition: padding .2s ease-in-out;
        }

        &--no-transition {
            transition: none;
        }

        &--active {
            padding-top: rem(14);
            padding-bottom: rem(14);
        }

        &--white & {
            &__link {
                color: $c-white;

                &--active::after {
                    background: $c-white;
                }

                &:hover {
                    color: rgba($c-white, .6);
                }
            }

            &__button {
                @include blur(15, .1, $c-white);

                &:hover {
                    color: $c-white;
                    @include blur(15, .2, $c-white);
                }
            }
        }

        &__progress-indicator {
            height: rem(3);
        }

        &__logo {
            width: rem(200);
        }

        &__content {
            display: flex;
            align-items: center;
            gap: rem(15);
        }

        &__link {
            color: $c-black;
            font-size: rem(16);
            transition: color .2s ease-in-out;
            position: relative;

            &--active {
                color: $c-primary;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(100% + .8rem);
                    left: 50%;
                    transform: translateX(-50%);
                    width: 50%;
                    height: rem(2);
                    background: $c-primary;
                    border-radius: rem(50);
                }
            }

            &:hover {
                color: $c-primary;
            }
        }

        &__menu {
            display: none;
        }
    }
}
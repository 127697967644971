.c-slider {

    &__cta {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(15);
    }

    &__image-container {
        position: relative;
        padding-top: calc((3/4) * 100%);
    }

    &__image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__item-content {
        padding: rem(14) rem(10) 0 rem(10);
    }

    &__label {
        font-family: $f-futura-pt;
        font-weight: 400;
        line-height: 1;
        color: $c-grey-4;
        font-size: rem(14);
        margin-bottom: rem(2);
    }

    &__text {
        margin-bottom: rem(15);
    }

    &__trigger {
        z-index: 2;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: rem(40);
        height: rem(40);
        border-radius: 100%;
        @include blur(15, .15, $c-white);

        &--prev {
            left: rem(35);
            padding-right: rem(2);
        }

        &--next {
            right: rem(35);
            padding-left: rem(2);
        }

        &--disabled {
            display: none;
        }

        &--icon {
            fill: rgba($c-white, .8);
        }
    }

    &__swiper {
        padding-left: rem(20);
        padding-right: rem(20);
    }

    @include screen(l) {
        &__swiper {
            width: 100%;
            max-width: $screen;
            margin-left: auto;
            margin-right: auto;
            @include fluid(padding-left, 10px, 150px, $large, $container);
            @include fluid(padding-right, 10px, 150px, $large, $container);
        }

        &__trigger {
            @include blur(15, .2, $c-white);
            transition: background .2s ease-in-out;

            &--prev {
                left: rem(40);
            }

            &--next {
                right: rem(40);
            }

            &--icon {
                fill: $c-white;
            }

            &:hover {
                @include blur(15, .4, $c-white);
            }
        }

        &__cta {
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-end;
            margin-bottom: rem(30);
        }

        &__item-content {
            padding: rem(24) rem(20) 0 rem(20);
        }
    }
}
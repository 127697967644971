.c-survey {
    text-align: center;
    position: relative;

    &--error{
        display: none;
        color: $c-error;
    }

    &__progress {
        position: absolute;
        display: flex;
        gap: rem(8);
        max-width: rem(220);
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        @include fluid(top, -20px, -40px, $large, $container);
    }

    &__progress-bar {
        display: block;
        width: 100%;
        height: rem(2);
        background: rgba($c-secondary, .15);
        border-radius: rem(50);

        &--active {
            background: $c-secondary;
        }
    }

    &__heading {
        @include fluid(margin-bottom, 30px, 50px, $large, $container);
    }

    &__item {
        @include fluid(margin-bottom, 30px, 50px, $large, $container);
    }

    &__title {
        color: $c-secondary;
        @include fluid(margin-bottom, 15px, 25px, $large, $container);
    }

    &__answers {
        display: grid;
        grid-template-columns: 1fr;
        @include fluid(gap, 8px, 15px, $large, $container);
    }

    &__answers-radio {
        display: none;
    }

    &__answers-radio:checked~&__answers-button {
        background: $c-secondary;
        color: $c-white;
    }

    &__answers-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-secondary;
        font-family: $f-futura-pt;
        font-size: rem(14);
        font-weight: 500;
        background: $c-secondary-light;
        border: rem(1) solid $c-secondary;
        text-transform: uppercase;
        @include fluid(border-radius, 14px, 16px, $large, $container);
        @include fluid(padding, 14px, 18px, $large, $container);
        user-select: none;


        &:hover {
            background: darken($c-secondary-light, 5);
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: rem(5);
    }

    &__actions-button {
        gap: rem(10);
    }

    @include screen(m-mobile) {
        &__actions-button {
            width: 100%;

            &--prev {
                border-radius: rem(50) 0 0 rem(50);
            }

            &--next {
                border-radius: 0 rem(50) rem(50) 0;
            }
        }
    }

    @include screen(m) {
        &__answers {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.c-text-text {
    &__content {
        display: flex;
        flex-direction: column;

        @include fluid(gap,15px, 80px, $large, $container);
    }

    &__column {
        max-width: rem(650);
    }

    @include screen(m) {
        &__content {
            justify-content: space-between;
            flex-direction: row;
        }
    }
}
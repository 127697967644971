.o-spacer {
    @include fluid(margin-bottom, 80px, 200px, $large, $container);

    &__small {
        @include fluid(margin-bottom, 40px, 80px, $large, $container);
    }

    &__title {
        @include fluid(margin-bottom, 20px, 35px, $large, $container);
    }
}

main {
    @include fluid(margin-top, 100px, 160px, $small, $container);

    &.o-spacer {
        &--blog {
            @include fluid(margin-top, 120px, 300px, $small, $container);
        }

        &--large {
            @include fluid(margin-top, 100px, 220px, $small, $container);
        }

        &--none {
            margin-top: 0;
        }
    }
}
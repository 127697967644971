.c-links {
    display: grid;
    grid-template-columns: 1fr;
    gap: rem(5);

    &__item {
        background: $c-secondary;
        position: relative;
        transition: background .2s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include fluid(padding-top, 25px, 40px, $large, $container);
        @include fluid(padding-right, 30px, 60px, $large, $container);
        @include fluid(padding-bottom, 25px, 40px, $large, $container);
        @include fluid(padding-left, 25px, 40px, $large, $container);

        &:hover {
            background: darken($c-secondary, 10);
        }
    }

    &__title {
        color: $c-white;
        margin-bottom: 0;
    }

    &__name {
        color: rgba($c-white, .8);
        font-weight: 400;
        font-size: rem(14);
        line-height: 1;
        margin-top: rem(6);
        margin-bottom: rem(2);
    }

    &__mail {
        color: rgba($c-white, .8);
        font-weight: 400;
        font-size: rem(14);
        text-decoration: underline;
        line-height: 1;
    }

    &__tertiary {
        color: $c-white;
        fill: $c-white;
        margin-top: rem(12);
        width: fit-content;
    }

    &__icon {
        position: absolute;
        top: rem(11);
        right: rem(11);
        width: rem(11);
        height: rem(11);
    }

    @include screen(s) {
        gap: rem(10);

        &--small {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include screen(m) {
        gap: rem(15);

        &--small {
            grid-template-columns: repeat(3, 1fr);
        }

        &--large {
            grid-template-columns: repeat(2, 1fr);
        }

        &__icon {
            top: rem(15);
            right: rem(15);
        }
    }

    @include screen(xl) {
        &--small {
            grid-template-columns: repeat(4, 1fr);
        }

        &--large {
            grid-template-columns: repeat(3, 1fr);
        }

        &__name,
        &__mail {
            font-size: rem(16);
        }

        &__icon {
            top: rem(20);
            right: rem(20);
            width: rem(14);
            height: rem(14);
        }
    }
}
// Futura PT = 100, 200, 300, 400, 500, 600, 700, 800, 900
@import url("https://use.typekit.net/hto8llx.css");

// Playfair Display = 100, 200, 300, 400, 500, 600, 700, 800, 900
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$f-futura-pt: 'futura-pt',
sans-serif;

$f-playfair: 'Playfair Display',
serif;
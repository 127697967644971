$screens: ('xs': (min-width: map-get($breakpoints, xs)),
  's': (min-width: map-get($breakpoints, s)),
  'm': (min-width: map-get($breakpoints, m)),
  'm-mobile': (max-width: map-get($breakpoints, m)),
  'l': (min-width: map-get($breakpoints, l)),
  'l-mobile': (max-width: map-get($breakpoints, l)),
  'xl': (min-width: map-get($breakpoints, xl)),
  'inner': (min-width: map-get($breakpoints, inner)),
  'container': (min-width: map-get($breakpoints, container)),
  'screen': (min-width: map-get($breakpoints, screen)),
);

@mixin screen($screen) {
  @if map-has-key($screens, $screen) {
    @media #{inspect(map-get($screens, $screen))} {
      @content;
    }
  }
}

@mixin blur ($radius, $opacity, $color:#000000) {
  background: rgba($color, $opacity*1.8);

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(rem($radius));
    background: rgba($color, $opacity);
  }
}

@mixin fluid($property, $min-size, $max-size, $min-vw, $max-vw) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-size);
  $u4: unit($max-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      #{$property}: $min-size;

      @media (min-width: $min-vw) {
        #{$property}: calc(#{$min-size} + #{strip-unit($max-size - $min-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media (min-width: $max-vw) {
        #{$property}: $max-size;
      }
    }
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

ol,
ul {
  margin: rem(15) 0;
  padding-left: rem(14);

  ul,
  ol {
    margin: rem(5) 0 rem(10) 0;
  }
}

li {
  position: relative;
  padding-left: rem(5);
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

html {
  font-size: 62.5%;
  background-color: transparent;
}

body {
  line-height: 1;
  background: $c-background;
}

select {
  display: block;
  background-color: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;

  &:focus {
    outline: none;
  }
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

::-moz-selection,
::selection {
  color: $c-primary;
  background: $c-secondary;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  outline: none;
}

input:focus {
  outline: 0;
}

input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


input[type=checkbox],
input[type=radio] {
  width: 13px;
  height: 13px;
}

input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

::-webkit-search-decoration {
  display: none;
}

button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
  overflow: visible;
  width: auto;
}

::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

select[multiple] {
  vertical-align: top;
}

#honeypot {
  display: none;
}
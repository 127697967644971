.c-accent {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include fluid(width, 220px, 550px, $small, $container);

    @include screen(s) {
        display: block;
    }
}
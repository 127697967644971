$breakpoints: (
    'xs': 400px,
    's': 576px,
    'm': 768px,
    'l': 992px,
    'xl': 1200px,
    'inner': 1400px,
    'container': 1600px,
    'screen': 1920px,
) !default;

$extra-small: map-get($breakpoints, xs);
$small: map-get($breakpoints, s);
$medium: map-get($breakpoints, m);
$large: map-get($breakpoints, l);
$extra-large: map-get($breakpoints, xl);
$inner: map-get($breakpoints, inner);
$container: map-get($breakpoints, container);
$screen: map-get($breakpoints, screen);
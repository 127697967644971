.c-button {
    font-size: rem(14);
    display: flex;
    justify-content: center;
    font-family: $f-futura-pt;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;

    &__primary {
        background: $c-primary;
        padding: rem(14) rem(20);
        border-radius: rem(100);
        color: $c-white;
    }

    &__secondary {
        background: $c-secondary;
        padding: rem(14) rem(20);
        border-radius: rem(100);
        color: $c-white;
    }

    &__ghost {
        border: rem(1) solid $c-black;
        padding: rem(13) rem(19);
        border-radius: rem(100);
        color: $c-black;

        &--blue {
            border: rem(1) solid $c-secondary;
            color: $c-secondary;
        }
    }

    &__tertiary {
        color: $c-black;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        gap: rem(8);
    }

    &__light {
        background: $c-primary-light;
        padding: rem(12) rem(20);
        border-radius: rem(100);
        color: $c-primary;
    }

    &__icon {
        margin-right: rem(6);
    }

    &--mt {
        margin-top: rem(30);
    }

    &--small {
        font-size: rem(12);
    }

    @include screen(s) {
        width: fit-content;
    }

    @include screen(l) {
        font-size: rem(16);
        transition: background .2s ease-in-out,
            color .2s ease-in-out;

        &__primary,
        &__secondary {
            padding: rem(15) rem(25);
        }

        &__primary {
            &:hover {
                background: darken($c-primary, 10);
            }
        }

        &__secondary {
            &:hover {
                background: darken($c-secondary, 10);
            }
        }

        &__ghost {
            padding: rem(14) rem(24);

            &:hover {
                background: $c-black;
                color: $c-white;
            }

            &--blue:hover {
                background: $c-secondary-light;
                color: $c-secondary;
            }
        }

        &--small {
            font-size: rem(14);
        }
    }
}
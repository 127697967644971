.c-cta {
    background-color: $c-primary-light;
    @include fluid(padding-top, 30px, 60px, $large, $container);
    @include fluid(padding-bottom, 30px, 60px, $large, $container);
    @include fluid(padding-right, 20px, 0px, $large, $container);
    @include fluid(padding-left, 20px, 0px, $large, $container);

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: rem(14);
    }

    &__title {
        margin-bottom: 0;
    }

    &__label {
        color: $c-secondary;
    }

    @include screen(m) {
        &__wrapper {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }
}